import React, { useEffect, useState } from "react";
import styles from "./mainPage.module.scss"
import Loader from "../../Components/Loader/loader";
import SplideHeader from "../../Components/Splide/Header/splideHeader";
import img1 from '../../assets/img/Header/splide/splide1.png'
import img2 from '../../assets/img/Header/splide/splide2.png'
import img3 from '../../assets/img/Header/splide/splide3.png'
import img4 from '../../assets/img/Header/splide/splide4.png'
import img5 from '../../assets/img/Header/splide/splide5.png'

export function MainPage({ isLong = false }) {
    const [data, setData] = useState(false)
    const allImg = [img1, img2, img3, img4, img5]

    useEffect(() => {
        setTimeout(() => {
            setData(true)
        }, 3);
    }, [])

    return (
        <React.Fragment>
            <Loader data={data} isLong={isLong} />
            <div className={styles.container}>
                <SplideHeader data={allImg} />
                <div className={styles.content}>
                    <h2>Prace techniczne</h2>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainPage