import React, { useEffect, useRef, useState } from 'react';
import styles from './loader.module.scss'

export function Loader({ data, isLong = false }) {
    const [isEnd, setIsEnd] = useState(false)
    const [isAnimationFinish, setIsAnimationFinish] = useState(false)
    // const [isLongAnim, setIsLongAnim] = useState(!localStorage.getItem('hasVisited'))
    const [isLongAnim] = useState(isLong)
    const cover = useRef(null)
    const containerRef = useRef(null)

    useEffect(() => {
        if (containerRef?.current) {
            setTimeout(() => { containerRef.current.classList.add(styles.show) }, 30)
        }
    }, [])

    useEffect(() => {
        if (data && isAnimationFinish) {
            if (cover?.current && containerRef?.current) {

                containerRef.current.classList.add(styles.hide)
                setTimeout(() => {
                    cover.current.classList.add(styles.hide)
                }, 300)

                setTimeout(() => {
                    setIsEnd(true)
                }, 600)
            }
        }
    }, [data, isAnimationFinish])

    useEffect(() => {
        if (isLongAnim) {
            localStorage.setItem('hasVisited', 'true');
            setTimeout(() => { setIsAnimationFinish(true) }, 1000)
        } else {
            setTimeout(() => { setIsAnimationFinish(true) }, 300)
        }
    }, [isLongAnim])

    return (
        <React.Fragment>
            {isEnd ? null : isLongAnim ?
                <div className={styles.containerLongAnim} ref={cover}>
                    <div className={styles.content} ref={containerRef}>
                        <div className={`${styles.element1} ${styles.img}`}></div>
                        <div className={`${styles.element2} ${styles.img}`}></div>
                        <div className={`${styles.element3} ${styles.img}`}></div>
                    </div>
                </div> :
                <div className={styles.containerShortAnim} ref={cover}>
                    <div className={styles.content} ref={containerRef}>
                        <div className={`${styles.element1} ${styles.img}`}></div>
                        <div className={`${styles.element2} ${styles.img}`}></div>
                        <div className={`${styles.element3} ${styles.img}`}></div>
                    </div>
                </div>
            }
        </React.Fragment >
    );
}

export default Loader