import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import '../../assets/styles/index.scss';
import MainPage from '../MainPage/mainPage';
import Header from '../Header/header';
import Blog from '../Blog/blog';

const App = ({ match, location }) => {
    return (
        <Suspense>
            <Switch>
                <Route exact path='/'>
                    <Header />
                    <MainPage isLong={true}/>
                </Route>
                <Route exact path='/blog'>
                    <Header />
                    <Blog />
                </Route>
            </Switch>
        </Suspense>
    );
};


function Builder() {
    return (
        <Router basename={"/"}>
            <App />
        </Router>
    );
}

export default Builder;