import React, { useRef } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css/core'
import styles from './splideHeader.module.scss'

function SplideHeader({ data }) {
    const splide = useRef(null)


    const startAutoplay = () => {
        splide.current.splide.Components.Autoplay.play()
    }

    // const stopAutoplay = () => {
    //     splide.current.splide.Components.Autoplay.pause()
    // }

    return (
        <Splide hasTrack={false} aria-label="..." className={styles.splide} ref={splide} onMouseEnter={startAutoplay} onMouseLeave={startAutoplay}
            options={{
                type: 'fade',
                perMove: 1,
                pagination: false,
                arrows: false,
                interval: 5000,
                autoplay: true,
                speed: 3000,
                rewind: true,
            }}
        >
            <SplideTrack className={styles.track}>
                {data?.map((item, index) => {
                    return (
                        <SplideSlide key={index} className={`${styles.element}`}>
                            <div className={`${styles.img}`} style={{ backgroundImage: `url(${item})` }} />
                        </SplideSlide>
                    )
                })}
            </SplideTrack>
        </Splide>
    )
}

export default SplideHeader