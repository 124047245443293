import React, { useEffect, useState } from "react";
import styles from "./blog.module.scss"
import Loader from "../../Components/Loader/loader";

export function Blog({isLong = false}) {
    const [data, setData] = useState(false)

    useEffect(()=>{
        setTimeout(() => {
            setData(true)
        }, 3);
    },[])

    return (
        <React.Fragment>
            <Loader data={data} isLong={isLong}/>
            <div className={styles.container}>
                <div className={styles.content}>
                    <h2>Prace techniczne</h2>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Blog