import React from "react";
import styles from "./header.module.scss"
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export function Header() {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.left}>
                    <Link to={"/"} className={styles.elementMenu}><span className={styles.text}>Moja strona</span></Link>
                </div>
                <div className={styles.middle}>
                    <div className={styles.conatinerInput}>
                        <input className={styles.input}></input>
                    </div>
                </div>
                <div className={styles.right}>
                    <Link to={"/blog"} className={styles.elementMenu}><span className={styles.text}>Blog</span></Link>
                    {/* <Link className={styles.elementMenu}><span className={styles.text}>Cennik</span></Link>
                    <Link className={styles.elementMenu}><span className={styles.text}>Rezerwacje</span></Link>
                    <Link className={styles.elementMenu}><span className={styles.text}>O mnie</span></Link>
                    <Link className={styles.elementMenu}><span className={styles.text}>Kontakt</span></Link> */}
                </div>
            </div>
        </div>
    )
}

export default Header